import React from "react";

import "./Navbar.css";

const Navbar = () => {
  const navCheckerRef = React.createRef();

  const toggle = (() => {
    let toggled = false;
    return () => {
      toggled = !toggled;
      navCheckerRef.current.checked = toggled;
      document.body.style.overflow = toggled ? "hidden" : "initial";
    };
  })();

  return (
    <React.Fragment>
      <input type="checkbox" id={"nav-checker"} ref={navCheckerRef} />
      <nav id={"main-nav"}>
        <div id={"brand"}>
          <div id={"name"} style={{ textAlign: "left", fontSize: "1.3rem" }}>
            Gemini
          </div>
          <div id={"name"} style={{ textAlign: "center" }}>
            Integrated
          </div>
          <div id={"name"} style={{ textAlign: "right", fontSize: "1.3rem" }}>
            Solutions
          </div>
        </div>
        <button
          id={"menu-toggle"}
          className={"fas fa-bars"}
          onClick={() => toggle()}
        />
        <div id={"links"}>
          <a href={"#speech"}>Quienes somos</a>
          <a href={"#services-header"}>Que hacemos</a>
          <a href={"#contact"}>Contactanos</a>
        </div>
      </nav>
      <aside id={"nav-menu"}>
        <ul>
          <li>
            <a href="#speech" onClick={() => toggle()}>
              Quienes somos
            </a>
          </li>
          <li>
            <a href="#services-header" onClick={() => toggle()}>
              Que hacemos
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => toggle()}>
              Contactanos
            </a>
          </li>
        </ul>
      </aside>
    </React.Fragment>
  );
};

export default Navbar;
